.wp-nav-bar {
    width: 100vw;
    height: 100px;
	border-bottom: 3px solid #f2d80f;
	background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(9,9,121,1) 35%, rgba(3,1,43,1) 74%, rgba(136,17,197,1) 100%);
}

.wp-logo {
    width:7.25rem;
    margin-left:2rem;

}

.wp-logo-mobile {
    width:5rem;
    margin-left:-.5rem;
}

.wp-nav-link-mobile, .wp-nav-link-mobile > a {
    color:#500b84 !important;
    font-weight:500;
    font-size:1.1rem;
}

.wp-nav-link, .wp-nav-link > a {
    color:#fff !important;
    font-weight:500;
    font-size:.95rem;
    white-space:nowrap !important;
}

.nav-app-button-mobile {
    position:absolute;
    top:1.5rem;
    z-index:10000;
}

.nav-app-button {
    position:absolute;
    top:1.5rem;
    right:2rem;
    z-index:10000;
  }
  
  .nav-app-button-mobile > a, .nav-app-button > a {
    color: #fff;
    font-weight: 500;
    font-size:.95rem;
    display: inline-block;
    border: 2px solid #a883e9;
    height: 50px;
    min-width: 178px;
    line-height: 46px;
    text-align: center;
    text-decoration: none;
    border-radius: 2rem;
  }
  
  .nav-app-button-mobile > a:hover, .nav-app-button > a:hover {
    background: #fb397d;
    color: #fff;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.navbar-toggler {
    border-color:#a883e9 !important;
    opacity:1 !important;
}

.wp-collapse-sm, .wp-collapse-xs {
    background:#fff !important;
    border-radius:1.5rem;
    border: 1px solid #500b84;
}

.wp-collapse-sm > a, .wp-collapse-xs > a {
    color:#500b84 !important;
}

.wp-collapse-sm {
    position: absolute;
    top:6.5rem;
    right:.5rem;
}

.wp-collapse-xs {
    position: absolute;
    top: 6.5rem;
    left:10rem;
}