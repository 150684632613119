.calc-header {
    text-align: center;
    height: 3rem;
    background: linear-gradient(to left, #0072ff, #8811c5);
    color: #fff;
    line-height: 3rem;
    font-size:1.25rem;
    font-weight:500;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin:0 !important;
}

.calc-header-mobile {
    text-align: center;
    height: 3rem;
    background: linear-gradient(to left, #0072ff, #8811c5);
    color: #fff;
    line-height: 3rem;
    font-size:.875rem;
    font-weight:500;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin:0 !important;
    font-weight:bold;
}

.calc-body {
    padding: 1rem;
    border: 1px solid #ced4da;
    margin-bottom: .25rem;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.calc-label {
    font-size:.925rem;
    font-weight: 600;
    color: #0f417e;
    margin-bottom:0;
    margin-left:.5rem;
}

.calc-input {
    border-radius: 2rem !important;
    border-color: #a865e1 !important;
    margin-bottom: .5rem !important;
    color: #0f417e !important;
    height:2.75rem !important; 
}

.contact-input {
    border-radius: 1rem !important;
    border-color: #a865e1 !important;
    margin-bottom: .5rem !important;
    color: #0f417e !important;
}

.foodlist-input {
    border-radius: 1rem !important;
    border-color: #a865e1 !important;
    margin-bottom: .5rem !important;
    color: #0f417e !important;
    padding:1.25rem 2.5rem !important;
}


.calc-button {
    min-width:5rem;
    border-radius:1rem !important;
}

.calc-results {
    width:70%;
    margin: 1rem auto 0 auto;
}

.calc-results > input {
    padding:2rem;
    border-radius:2rem !important;
    text-align:center;
    font-weight:bold;
    font-size:1.25rem;
}

.zp-checkbox {
    font-size:1.1rem;
    margin-left:1.5rem;
}

.zp-checkbox > input {
    height:1.5rem;
    width:1.5rem;
}

.green {
    color:#006400
}

.blue {
    color:#0d4f8b
}

.purple {
    color:#8f1965
}  