@import '../styles/font-awesome.min.css';
@import '../styles/animate.css';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Cabin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.welcome-area {
  background-image: linear-gradient(0deg, hsl(208, 100%, 65%) 0%, #62ddff 100%);
  height: 350px;
  width: 100% !important;
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}

.welcome-heading {
  position: relative;
  z-index: 1;
  top: 100px;
  width: 100%;
  padding: 1.5rem 12.5rem 5rem 7.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.welcome-heading-mobile,
.welcome-heading-mobile-large {
  position: relative;
  z-index: 1;
  top: 100px;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
}

.welcome-heading>h2 {
  font-size: 2.75rem;
  font-weight: 600;
  color: #6610f2;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.welcome-heading-mobile>h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #6610f2;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.welcome-heading-mobile-large>h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #6610f2;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.w-h1-pink {
  animation-name: w-h1-pink;
  animation-timing-function: ease-in-out;
}

@keyframes w-h1-pink {
  0% {
    color: #6610f2;
  }

  50% {
    color: #fb397d;
  }

  100% {
    color: #6610f2;
  }
}

.welcome-heading>h3 {
  font-size: 20rem;
  position: absolute;
  top: -75px;
  font-weight: 900;
  margin-left: -300px;
  z-index: 2;
  color: #fff;
  opacity: .075;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.welcome-heading-mobile>h3,
.welcome-heading-mobile-large>h3 {
  font-size: 15rem;
  position: absolute;
  top: -2rem;
  font-weight: 900;
  margin-left: -300px;
  z-index: 2;
  color: #fff;
  opacity: .075;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.welcome-heading>p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.welcome-heading-mobile>p {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.welcome-app-button>a {
  position: relative;
  z-index: 3;
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
  display: inline-block;
  height: 50px;
  min-width: 15rem;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 2rem 2rem 2rem 0;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  cursor: pointer;
}


.welcome-app-button-mobile>a {
  position: relative;
  z-index: 3;
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
  min-width: 11rem;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 2rem 2rem 2rem 0;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  cursor: pointer;
}


.bkgd-color-change {
  background: #fb397d;
  transition: background-color 0.5s ease;
}

.bkgd-color-change:hover {
  background: #6610f2;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-75px);
  }

  100% {
    transform: translateY(0);
  }
}

.menu_area nav ul li>a:hover {
  color: #fb397d;
}

.container {
  width: 100% !important;
  margin: 2.5rem auto;
}

.container-home,
.container-home-mobile {
  width: 100%;
  margin: 50px auto;
}

.container-mobile {
  width: 100%;
  margin: 2.5rem auto 2rem auto;
}

.page-content {
  margin: auto;
}

.page-content-mobile {
  width: 95%;
  margin: auto;
}

.page-content>h1,
.page-content>h2,
.page-content-mobile>h1,
.page-content-mobile>h2 {
  color: #5b32b4;
  font-size: 44px;
  margin: 4rem auto 2rem auto;
  text-align: center;
  font-weight: 300;
}

.accordion {
  width: 95%;
  margin: auto;
  margin-top: 2rem;
}

.accordion-card>.card-header {
  background: rgb(250, 250, 250) !important;
  padding-left: 2rem;
}

.accordion-h1,
.accordion-h2 {
  color: #5b32b4;
  margin: auto;
  text-align: center;
  font-weight: 300;
}

.accordion-expand {
  position: absolute;
  top: 1rem;
  left: .5rem;
  width: 1rem;
}

.line-shape {
  width: 20rem;
  height: 2px;
  background-color: #fb397d;
  margin: 1.5rem auto;
}

.art-content {
  width: 75%;
  margin: auto;
}

.art-content-mobile {
  width: 97.5%;
  margin: auto;
}

.art-content>h3,
.art-content-mobile>h3 {
  color: #fb397d;
  font-size: 24px;
  margin: 2rem auto 2rem auto;
  text-align: left;
  font-weight: 400;
}

.art-content-list-title {
  margin-bottom: 0;
  font-weight: 500 !important;
}

.art-content>p,
.art-content-mobile>p {
  color: #1e1c24;
  font-size: 20px;
  font-weight: 300;
  margin-top: 2rem;
  text-align: justify;
  line-height: 2rem;
}

.art-content-mobile>p {
  color: #1e1c24;
  font-size: 20px;
  font-weight: 300;
  margin-top: 2rem;
  text-align: left;
  line-height: 2rem;
}

.art-content>ol {
  font-size: 20px;
  color: #1e1c24;
  font-weight: 300;
  margin-top: 1rem;
  margin-left: 1rem;
  text-align: justify;
}

.art-content-mobile>ol {
  font-size: 20px;
  color: #1e1c24;
  font-weight: 300;
  margin-top: 1rem;
  margin-left: 1rem;
  text-align: left;
}

.art-content>ol>li,
.art-content-mobile>ol>li {
  margin-top: 1rem;
}

.art-content>ul {
  color: #1e1c24;
  font-weight: 300;
  margin-top: 1rem;
  margin-left: 1rem;
  list-style-type: none;
  text-align: justify;
}

.art-content-mobile>ul {
  color: #1e1c24;
  font-weight: 300;
  margin-top: 1rem;
  margin-left: 1rem !important;
  padding-left: 0 !important;
  list-style-type: none;
  text-align: left;
}

.art-content>ul>li,
.art-content-mobile>ul>li {
  margin-top: .5rem;
  font-size: 20px;
  line-height: 2rem;
}

.art-content-indent {
  margin-left: 1.5rem;
}

.contact-form,
.captcha-container {
  width: 60%;
  margin: auto;
}

.contact-form-mobile,
.captcha-container-mobile {
  width: 80%;
  margin: auto;
}

.footer-contact-area {
  background: #f5f9fc;
}

.footer-text>p {
  margin-bottom: 3.5rem;
}

/* --------------------------
:: 14.0 Footer Area CSS Start
-------------------------- */

.footer-text>h2 {
  color: #5b32b4;
  font-size: 56px;
  font-weight: 600;
}

.footer-social-icon>a>i {
  background: #9b69e4;
  font-size: 14px;
  color: #ffffff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-weight: 500;
  border-radius: 1rem;
  margin-right: 10px;
}

.footer-social-icon>a>i:hover {
  background: #fb397d;
  color: #fff;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.social-icon {
  margin-bottom: 30px;
}

.footer-text {
  margin-bottom: 15px;
}

.footer-menu ul li {
  display: inline-block;
}

.footer-menu ul li a {
  border-right: 2px solid #726a84;
  display: block;
  padding: 0 7.5px;
  color: #726a84;
}

.footer-social-icon>a .active {
  background: #fb397d;
}

.footer-menu ul li:last-child a {
  border-right: 0 solid #ddd;
}

.footer-social-icon {
  margin-bottom: 30px;
}

.footer-menu {
  margin-top: 3rem;
  margin-bottom: 15px;
}

.copyright-text>p {
  margin-bottom: 0;
}

.triangle-bkgd {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 25rem solid transparent;
  border-right: 25rem solid transparent;
  border-top: 25rem solid #7120ce;
  opacity: .05;
  z-index: -1;
}

.triangle-bkgd-about-us {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 35rem solid transparent;
  border-right: 35rem solid transparent;
  border-top: 6rem solid #7120ce;
  opacity: .05;
  z-index: -1;
}

.sp-table>tbody>tr>td {
  border-top: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.sp-button-desktop,
.ap-button-desktop,
.pp-button-desktop,
.zp-button-desktop {
  width: 100%;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 600;
}

.sp-button-mobile,
.ap-button-mobile,
.pp-button-mobile,
.zp-button-mobile {
  width: 100%;
  margin-top: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.sp-button-mobile-wide,
.ap-button-mobile-wide,
.pp-button-mobile-wide,
.zp-button-mobile-wide {
  width: 45%;
}

.pp-button-mobile-wide,
.zp-button-mobile-wide {
  margin-top: 1rem !important;
}

.ap-button-mobile-wide,
.zp-button-mobile-wide {
  margin-left: 1rem !important;
}

.sp-button-fixed {
  position: fixed;
  bottom: 14.75rem;
  right: 2.5rem;
  min-width: 12.5rem;
}

.ap-button-fixed {
  position: fixed;
  bottom: 11.25rem;
  right: 2.5rem;
  min-width: 12.5rem;
}

.pp-button-fixed {
  position: fixed;
  bottom: 7.75rem;
  right: 2.5rem;
  min-width: 12.5rem;
}

.zp-button-fixed {
  position: fixed;
  bottom: 4.25rem;
  right: 2.5rem;
  min-width: 12.5rem;
}